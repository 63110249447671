





















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { Customer } from '@/interfaces/customer';
import SelectDocument from '@/components/SelectDocument.vue';
import InputDocument from '@/components/InputDocument.vue';

@Component({
    components: { SelectDocument, InputDocument }
})
export default class FillInformation extends Vue {
    @Prop() customer?: Customer;
    @Prop({ type: String }) classLabelDocument!: string;
    @Prop({ type: [String, Object, Array] }) classSelectDocument!: string;
    @Prop({ type: [String, Object, Array] }) classInputDocument!: string;
    @PropSync('data', { required: true }) form!: Record<string, string>;

    created() {
        if (this.customer) {
            this.$set(this.form, 'documentType', this.customer.documentType);
            this.$set(this.form, 'documentNumber', this.customer.documentNumber);
        }
    }
}
